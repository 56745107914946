$primary: #009445;
$success: $primary;
$light: #f8f9fa;
$info: #1b75bc;
$secondary: rgb(158, 162, 166);
$body-color: rgb(48, 52, 56);
$dark: rgb(48, 52, 56);
$black: rgb(48, 52, 56);
$min-contrast-ratio: 2;
$modal-content-border-width: 0;
$enable-caret: false;
$table-cell-padding-x: 1rem;
$input-placeholder-color: darkgray;
$dropdown-padding-x: 0.5rem;
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$dropdown-item-padding-x: $spacer * 0.75;
$dropdown-link-hover-bg: gainsboro;
$alert-border-radius: 0;
$alert-border-width: 0;
html {
  scroll-behavior: smooth;
}
.dropdown-menu {
  @extend .shadow;
}
.dropdown-item {
  @extend .rounded;
  @extend .small;
  @extend .my-1;
}

tbody > tr:last-child > td {
  border-bottom: 0;
}

.dropdown-item:active #logs-icon {
  fill: white;
}

.tooltip-inner {
  max-width: none !important;
}

.bg-orange {
  background-color: #f48620;
}

.sidebar > div > .nav-item > .nav-link:not(.active):not(.disabled) {
  color: $body-color !important;
}

.mobile-sidebar > div > .nav-item > .nav-link:not(.active):not(.disabled) {
  color: $body-color !important;
}

.mobile-sidebar > div > .nav-item > .nav-link {
  @extend .small;
  padding: 8px 0px;
  margin: 4px 0;
}

.mobile-sidebar::-webkit-scrollbar {
  display: none;
}
.mobile-sidebar {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar > div > .nav-item > .nav-link {
  @extend .small;
  padding: 6px 12px;
  margin: 4px 0;
}

.sidebar > div > .nav-item > .nav-link:not(.active):hover {
  background: #e9ecef;
}

.bg-gainsboro {
  background: gainsboro;
}

.react-datepicker__close-icon::after {
  border-radius: 6px;
  color: rgb(48, 52, 56);
  background: gainsboro;
  font-size: 16px;
  cursor: pointer;
  padding: 0 !important;
  &:hover {
    border-radius: 6px;
    color: darkred;
    background: tomato;
    font-size: 16px;
    cursor: pointer;
  }
}

.badge.bg-none {
  @extend .text-muted;
  @extend .border;
}

.table {
  margin-bottom: 0 !important;
}
.measurement-editor {
  background: rgba(55, 55, 55, 75%) !important;
  color: white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.feature-card {
  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
  &:active {
    filter: brightness(90%);
  }
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

@media only screen and (max-width: 1400) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.table:not(.storagesTable) thead tr {
  @extend .initialism;
  @extend .text-black-50;
  @extend .table-active;
}

.hover-gray:hover {
  background: #e9ecef !important;
}

@import "~bootstrap/scss/bootstrap.scss";
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);
