body {
  background: url("bg_main.jpg") top center/cover no-repeat fixed;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  max-height: 24px;
}
.text-line-middle {
  display: flex;
  flex-direction: row;
}
.text-line-middle:before,
.text-line-middle:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid darkgray;
  margin: auto;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dot {
  animation: blink 2s infinite;
}
.text-line-middle:before {
  margin-right: 1rem;
}
.text-line-middle:after {
  margin-left: 1rem;
}
.offcanvas-title {
  line-height: 1;
  color: white;
}
.offcanvas-header > .btn-close {
  margin-left: 1rem;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-group-select {
  padding: 0;
  background: transparent;
  border: 0;
  height: auto;
}

.leaflet-control-attribution {
  display: none;
}

p {
  margin: 0 !important;
}

input[type="checkbox"] {
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-radius: 0.25em;
}

input[type="checkbox"]:checked {
  background-color: #009445;
  border-color: #009445;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

input[type="checkbox"]:focus {
  border-color: #007e3b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(38 164 97 / 25%);
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

label[title] {
  margin-left: 2px;
  font-weight: normal;
}

select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
/*
.form-control:focus {
  border-color: transparent;
}

.form-control:focus,
input[type="date"]:focus {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.form-control,
input,
.select {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
  border-radius: 4px !important;
} */

.leaflet-popup-content-wrapper {
  box-shadow: none;
}

.w-full {
  width: 100%;
}

.marker-header {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #009445;
  margin-right: 2rem;
}

.form-control-append {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-control-prepend {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.add-storage {
  color: rgba(255, 255, 255, 50%);
  text-align: center;
}
.add-storage:hover {
  color: rgba(255, 255, 255, 75%);
  cursor: pointer;
}
.add-storage:active {
  color: rgba(255, 255, 255, 100%);
}

.underline {
  text-decoration: underline !important;
}

.storagesTable {
  border-collapse: separate;
  border-spacing: 0px;
}

.rounded-table th:first-child {
  border-radius: 6px 0 0 0 !important;
}
.rounded-table th:last-child {
  border-radius: 0 6px 0 0 !important;
}
.rounded-table tr:last-child td:first-child {
  border-radius: 0 0 0 6px !important;
}
.rounded-table tr:last-child td:last-child {
  border-radius: 0 0 6px 0 !important;
}

.storagesTable th,
.storagesTable td {
  padding: 0.5rem;
  white-space: nowrap;
  vertical-align: middle;
}

.storagesTable th {
  font-weight: 500;
  text-transform: uppercase;
}

.containersTable {
  border-collapse: separate;
  border-spacing: 0px;
  background: transparent !important;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.bg-green {
  background-color: #009445;
}

.bg-whitesmoke {
  background: whitesmoke;
}

.bg-white {
  background: white;
}

.no-focus:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.text-orange {
  color: #f48620;
}

.marker-cluster span {
  line-height: 32px !important;
}

.custom-marker {
  border-radius: 6px;
  border: 6px solid #009445;
  background-color: #009445;
  width: 2rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 10%);
}

.leaflet-popup-content {
  min-width: 12rem;
  width: auto;
  margin: 0.5rem !important;
}

.marker-cluster:hover {
  filter: brightness(90%);
}

.marker-cluster div {
  width: 28px !important;
  height: 28px !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  margin-top: 5px !important;
  margin-left: 6px !important;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: #f48620 !important;
  color: white;
}
.marker-cluster-small > div,
.marker-cluster-medium > div,
.marker-cluster-large > div {
  background-color: transparent !important;
}

a.leaflet-popup-close-button {
  top: 8px !important;
  right: 4px !important;
  font-weight: bold;
}
